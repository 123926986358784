@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
.d {
  width: 100% !important;
  height: 100vh;
  background-color: #292d7d;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wrapper {
  width: 50% !important;
  padding-top: 60px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}
.text-area {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.text-area img {
  width: 70px;
  height: 70px;
}

.text-area h1 {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 57px;
  color: #ffffff;
  margin: 20px 0;
}

.text-area p {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  max-width: 456px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
  opacity: 0.6;
}
.sircles-img {
  margin-top: 22%;
}

.sircles-img img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 700px) {
  .text-area h1 {
    font-size: 37px;
  }
  .text-area p {
    font-size: 19px;
  }
}
@media screen and (max-width: 590px) {
  .text-area h1 {
    font-size: 34px;
  }
  .text-area p {
    font-size: 16px;
  }
  .wrapper {
    padding-top: 70px;
  }
}
